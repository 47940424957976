* {
  margin: 0;
  padding: 0;
}

@font-face {
  font-family: 'Public-sans';
  src: local('Public-sans'), url('../fonts/PublicSans-Regular.ttf') format('truetype');
}

@font-face {
  font-family: 'NeulisAlt';
  src: local('NeulisAlt'), url('../fonts/NeulisAlt-Medium.ttf') format('truetype');
}

@font-face {
  font-family: 'Montserrat';
  src: local('Montserrat'), url('../fonts/montserrat.regular.ttf') format('truetype');
}

@import url('https://fonts.googleapis.com/css2?family=Public+Sans:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap');

body,
html {
  font-family: 'Public-sans' !important;
  background-color: #fafafa;
}

:root {
  --primary-font: 'Public-sans';
}

.common-paper {
  margin: 16px 64px;
  padding: 32px 16px;
  box-shadow: 0 10px 24px rgb(12 27 42 / 6%) !important;
  background: #fffffe;
  border-radius: 8px !important;
}

.table-body-row:hover {
  background: linear-gradient(0deg, rgb(12 27 42 / 6%), rgb(12 27 42 / 6%)), rgb(255 255 255 / 80%);
}

.table-icon {
  opacity: 0;
}

.table-body-row:hover .table-icon {
  opacity: 1;
  cursor: pointer;
}

.custom-success {
  border-radius: 16px !important;
  background-color: rgba(180, 215, 75, 0.9) !important;
  width: 320px !important;
  padding: 8px 16px;
}

.custom-error {
  border-radius: 16px !important;
  background-color: rgba(189, 136, 144, 0.9) !important;
  width: 320px !important;
  padding: 8px 16px;
}

* {
  -ms-overflow-style: none;
}

::-webkit-scrollbar {
  display: none;
}
