.quill-container {
    width: 100%;
    border: 1px solid #9a9fa5;
    border-radius: 5px;
  }

.ql-editor {
  font-family: 'Public-sans';
  font-size: 14px;
  min-height: 200px;
}
.ql-container.ql-snow {
  border: none;
}
.ql-toolbar.ql-snow {
  border-bottom: 1px solid #9a9fa5;
  border-top: none;
  border-left: none;
  border-right: none;
  font-family: 'Public-sans';
}
  