.leftRoot {
    background-color: #0c1b2a;
    display: flex;
    align-items: center;
    justify-content: flex-start;
    padding-left: 50px;
    padding-right: 50px;
}

.rightRoot {
    background-color: #fcfcfc;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
}

.leftContentWrapper {
    margin-left: 0;
    max-width: 470px;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
}

.headerLabel {
    font-family: NeulisAlt;
    font-size: 40px;
    font-weight: 400;
    color: #fcfcfc;
    line-height: 40px;
    font-style: italic;
}

.welcomLabel {
    font-size: 16px;
    font-weight: 300;
}

.headerLabelRight {
    font-family: NeulisAlt;
    font-size: 32px;
    font-weight: 400;
    color: #343e47;
    line-height: 40px;
    font-style: italic;
}

.subHeader {
    font-family: Montserrat;
    font-size: 14px;
    font-weight: 400;
    color: #788491;
    text-align: left;
    margin-top: 8px;
}

.description {
    font-size: 16px;
    font-weight: 300;
    color: #fcfcfc;
    text-align: left;
    margin-top: 28px;
    line-height: 30px;
}

.rightContent {
    display: flex;
    flex-direction: column;
    align-items: center;
}

.logoWrapper {
    display: flex;
    margin-bottom: 41px;
    flex-direction: column;
    align-items: center;
}

.button {
    display: flex;
    flex-direction: row;
    cursor: pointer;
    box-shadow: 0 3px 1px -2px rgb(0 0 0 / 20%), 0 2px 2px 0 rgb(0 0 0 / 14%), 0 1px 5px 0 rgb(0 0 0 / 12%);
}

.leftWrapper {
    background-color: #354968;
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 13px;
    border-radius: 5px 0 0 5px;
}

.rightWrapper {
    background-color: #0c1b2a;
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 13px;
    border-radius: 0 5px 5px 0;
    min-width: 273px;
}

.buttonLabel {
    font-family: Montserrat, sans-serif;
    font-size: 16px;
    font-weight: 700;
    color: #fcfcfc;
    line-height: 20px;
    cursor: pointer;
}

@media screen and (max-width: 899px) {
    .leftRoot {
        justify-content: center;
        align-items: center;
        padding-left: 30px;
        padding-right: 30px;
    }

    .leftContentWrapper {
        align-items: center;
    }

    .headerLabel {
        font-size: 26px;
    }

    .subHeader {
        text-align: center;
    }

    .description {
        text-align: center;
    }
}
