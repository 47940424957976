.root {
    background-color: #fffffe;
    padding: 32px 64px 16px;
    display: grid;
}

.subHeader {
    font-weight: 300;
    font-size: 16px;
    margin-top: 8px;
    color: #343e47;
    letter-spacing: 0.04em;
}
