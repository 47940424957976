.rootButton {
    text-transform: none;
    justify-content: flex-start;
    padding: 0;
    width: fit-content;
    color: #BD8890;
    gap: 6px;
    margin-top: 10px;
}

.rootButton:hover {
    background-color: #FFFFFE;
}

.spanText {
    text-decoration: underline;
    font-size: 16px;
    font-weight: 400;
    color: #BD8890;
}